<template>
  <b-container fluid>
    <b-row>
      <b-col lg="12" sm="12">
        <b-row>
          <b-col md="12" v-if="showData">
            <b-overlay>
              <iq-card>
                <template v-slot:body>
                  <b-row>
                    <b-col md="12">
                      <iq-card>
                        <!-- <template v-slot:headerTitle>
                          <h4 class="card-title">{{ $t('bsri_demandDIS.subsidy_requisition_and_approval') }}AAA</h4>
                        </template> -->
                        <template v-slot:body>
                          <b-overlay :show="loading">
                            <b-row mt-5>
                              <b-col md="12" class="table-responsive">
                                <div style="border: 2px solid;margin:10px;padding:10px">
                                <div class="text-black">
                                <b-row>
                                    <b-col md="4"><strong>{{ $t('elearning_config.fiscal_year') }}: </strong>{{getFiscalYear}}</b-col>
                                </b-row>
                                 </div>
                                  <b-row>
                                    <b-col>
                                      <b-table-simple class="tg mt-3" bordered striped hover small caption-top responsive>
                                        <b-thead>
                                          <tr>
                                            <b-th style="vertical-align : middle;text-align:center;" class="text-center" rowspan="3">{{ $t('globalTrans.sl_no') }}</b-th>
                                            <b-th style="vertical-align : middle;text-align:center;" class="text-center" rowspan="3">{{ $t('bsri_demandDIS.mill') }}</b-th>
                                            <b-th style="vertical-align : middle;text-align:center;" class="text-center" rowspan="3">{{ $t('bsri_demandDIS.total_farmer') }}</b-th>
                                            <b-th class="text-center" :colspan="10">{{ $t('bsri_demandDIS.cultivation_method_and_subsidy_amount') }}</b-th>
                                          </tr>
                                           <tr>
                                              <!-- <th colspan="2" style="vertical-align : top;font-size: 11px;" v-for="(culItem, culIndex) in culMethod" :key="culIndex">{{ ($18n.locale==='bn') ? culItem.method_name_bn : culItem.method_name }} </th> -->
                                              <th colspan="2" style="vertical-align : top;font-size: 11px;" v-for="(culItem, culIndex) in culMethod" :key="culIndex">
                                                {{ $i18n.locale === 'en' ? culItem.method_name : culItem.method_name_bn }} <br/>( {{ $n(culItem.metPolicy.per_acr_amount, { useGrouping: false }) }} {{ $t('bsri_demandDIS.per_acre') }})
                                                </th>
                                              <th colspan="2" style="vertical-align : top;font-size: 11px;" >{{ $t('bsri_demandDIS.in_total') }}</th>
                                          </tr>
                                          <tr>
                                            <th style="vertical-align : top;font-size: 9px;">{{ $t('bsri_demandDIS.lnd_quantity') }}</th>
                                            <th style="vertical-align : top;font-size: 9px;">{{ $t('bsri_demandDIS.amount_money') }}</th>
                                            <th style="vertical-align : top;font-size: 9px;">{{ $t('bsri_demandDIS.lnd_quantity') }}</th>
                                            <th style="vertical-align : top;font-size: 9px;">{{ $t('bsri_demandDIS.amount_money') }}</th>
                                            <th style="vertical-align : top;font-size: 9px;">{{ $t('bsri_demandDIS.lnd_quantity') }} </th>
                                            <th style="vertical-align : top;font-size: 9px;">{{ $t('bsri_demandDIS.amount_money') }} </th>
                                            <th style="vertical-align : top;font-size: 9px;">{{ $t('bsri_demandDIS.lnd_quantity') }} </th>
                                            <th style="vertical-align : top;font-size: 9px;">{{ $t('bsri_demandDIS.amount_money') }} </th>
                                            <th style="vertical-align : top;font-size: 9px;">{{ $t('bsri_demandDIS.lnd_quantity') }} </th>
                                            <th style="vertical-align : top;font-size: 9px;">{{ $t('bsri_demandDIS.amount_money') }}  </th>
                                          </tr>
                                        </b-thead>
                                       <!-- <b-tbody  v-for="(culItem2, culIndex2) in culMethod" :key="culIndex2"> -->
                                       <b-tbody>
                                       <b-tr  v-for="(millItem, millIndex) in approvedMillList" :key="millIndex">
                                          <b-td class="text-center" style="font-size: 9px;">{{ millIndex + 1 }}</b-td>
                                          <b-td class="text-center" style="font-size: 9px;"> {{ $i18n.locale === 'en' ? millItem.mill_name_en : millItem.mill_name_bn }}</b-td>
                                          <b-td class="text-center" style="font-size: 9px;">{{ $n(millItem.totalFalmer, { useGrouping: false }) }}</b-td>
                                          <template v-for="(culItem2, culIndex2) in culMethod">
                                            <td :key="culIndex2+1" style="font-size: 9px;">{{ millItem.policyLand[culItem2.id] ? $n(millItem.policyLand[culItem2.id], { useGrouping: false }) : $n(0, { useGrouping: false }) }}</td>
                                            <td :key="culIndex2+2" style="font-size: 9px;"> {{ $n((culItem2.metPolicy.per_acr_amount) * (millItem.policyLand[culItem2.id] ? millItem.policyLand[culItem2.id] : 0), { useGrouping: false }) }}</td>
                                          </template>
                                          <b-td class="text-center" style="font-size: 9px;">{{ $n(millItem.verifiedLandTotal, { useGrouping: false }) }}</b-td>
                                          <b-td class="text-center" style="font-size: 9px;">{{ $n(millItem.amountTotal, { useGrouping: false }) }}</b-td>
                                        </b-tr>
                                         <b-tr >
                                            <b-th colspan="3" style="vertical-align : top;font-size: 9px;text-align: right;">{{ $t('bsri_demandDIS.total') }}</b-th>
                                             <template v-for="(culItem3, culIndex3) in culMethod">
                                            <b-td :key="culIndex3+1" style="font-size: 9px;">{{ policyLandGrandTotal[culItem3.id] ? $n(policyLandGrandTotal[culItem3.id], { useGrouping: false }) : $n(0, { useGrouping: false }) }}</b-td>
                                            <b-td :key="culIndex3+2" style="font-size: 9px;"> {{ $n((culItem3.metPolicy.per_acr_amount) * (policyLandGrandTotal[culItem3.id] ? policyLandGrandTotal[culItem3.id] : 0), { useGrouping: false }) }}</b-td>
                                          </template>
                                          <b-td class="text-center" style="font-size: 9px;">{{ $n(verifiedLandGrandTotal, { useGrouping: false }) }}</b-td>
                                          <b-td class="text-center" style="font-size: 9px;">{{ $n(amountGrandTotal, { useGrouping: false }) }}</b-td>
                                        </b-tr>

                                        </b-tbody>
                                      </b-table-simple>
                                    </b-col>
                                  </b-row>
                                    <!-- ============Start=============== -->
                                  <b-row>
                                    <b-col>
                                      <b-table-simple class="tg mt-3" bordered striped hover small caption-top responsive>
                                       <b-tbody>
                                          <tr>
                                            <th style="vertical-align : top;font-size: 15px;">{{ $t('bsri_demandDIS.requisition_amount') }}:</th>
                                            <td style="vertical-align : top;font-size: 15px;">{{ $n(subsAllocate.actual_amount, { useGrouping: false }) }} </td>
                                            <th style="vertical-align : top;font-size: 15px;">{{ $t('bsri_demandDIS.allocated_subsidy_amount') }}:</th>
                                            <td style="vertical-align : top;font-size: 15px;">{{ $n(subsAllocate.allocated_amount, { useGrouping: false }) }} </td>
                                            <th style="vertical-align : top;font-size: 15px;">{{ $t('bsri_demandDIS.percentage') }} (%):</th>
                                            <td style="vertical-align : top;font-size: 15px;">{{ $n(subsAllocate.percentage, { useGrouping: false }) }} %</td>
                                            <th style="vertical-align : top;font-size: 15px;">{{ $t('bsri_demandDIS.note')}}:</th>
                                            <td style="vertical-align : top;font-size: 15px;">{{ $n(subsAllocate.note, { useGrouping: false }) }}</td>
                                          </tr>
                                        </b-tbody>
                                      </b-table-simple>
                                    </b-col>
                                  </b-row>
                                    <!-- ============End================= -->
                                </div>
                              </b-col>
                            </b-row>
                          </b-overlay>
                        </template>
                      </iq-card>
                    </b-col>
                    <b-col xs="12" sm="12" md="12" lg="12" xl="12">
                      <div class="text-right mt-4">
                        <b-button variant="danger" @click="$bvModal.hide('modal-details')">{{ $t('globalTrans.cancel') }}</b-button>
                      </div>
                    </b-col>
                  </b-row>
                </template>
              </iq-card>
            </b-overlay>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { core } from '@/config/pluginInit'
import RestApi, { incentiveGrantServiceBaseUrl } from '@/config/api_config'
import { subsidyRequisitionAndApprovalMillWiseSubAmount, subsizeAlloApprove } from '../../api/routes'
import flatpickr from 'flatpickr'

export default {
    props: ['fiscalYearId'],
    created () {
       if (this.fiscalYearId) {
            this.loadData()
        }
    },
    data () {
        return {
          search: {
            fiscal_year_id: this.fiscalYearId
          },
          allocationData: '',
          searchResult: {
            fiscal_year: '',
            fiscal_year_bn: ''
          },
          incentiveGrantServiceBaseUrl: incentiveGrantServiceBaseUrl,
          datas: [],
          approvedMillList: [],
          approvedList: [],
          culMethod: [],
          policyLandGrandTotal: [],
          verifiedLandGrandTotal: 0,
          amountGrandTotal: 0,
          formData: {
            fiscal_year_id: this.fiscalYearId,
            actual_amount: this.amountGrandTotal,
            allocated_amount: 0,
            percentage: 0,
            note: '',
            status: 3
          },
          loading: false,
          showData: true,
          subsidyList: [],
          upazilaList: [],
          unionList: [],
          cultivationMethod: [],
          subzoneOfficeList: [],
          subsAllocate: ''
        }
    },
    mounted () {
        core.index()
        flatpickr('.datepicker', {})
        // this.headerDataExcel()
    },
    computed: {
      getFiscalYear: function () {
        const fYearObj = this.$store.state.commonObj.fiscalYearList.find(item => item.status === 0 && item.value === this.fiscalYearId)
           if (this.$i18n.locale === 'bn') {
                return fYearObj.text_bn
            } else {
                return fYearObj.text_en
            }
      },
      fiscalYearList: function () {
        return this.$store.state.commonObj.fiscalYearList.filter(item => item.status === 0)
      },
      millInfoList: function () {
        const millInfoList = this.$store.state.incentiveGrant.commonObj.millInfoList.filter(item => item.status === 1)
        return millInfoList.map(item => {
            if (this.$i18n.locale === 'bn') {
                return { value: item.value, text: item.text_bn }
            } else {
                return { value: item.value, text: item.text_en }
            }
        })
      }
    },
    watch: {
      'search.mill_info_id': function (newVal, oldVal) {
        if (oldVal !== newVal) {
          this.subzoneOfficeList = this.getSubZoneList(newVal)
        }
      },
      'formData.allocated_amount': function (newVal, oldVal) {
        if (newVal > this.amountGrandTotal) {
          this.$swal({
            title: 'Allcated Amount Grater Than Main Amount',
            showCancelButton: false,
            confirmButtonText: 'Ok',
            cancelButtonText: 'No',
            focusConfirm: false
          }).then((resultSwal) => {
            this.formData.allocated_amount = oldVal
          })
        } else if (newVal === '' || newVal === 0) {
          this.formData.percentage = 0
        } else if (newVal > 0) {
         this.formData.percentage = (newVal * 100) / this.amountGrandTotal
        }
      }
    },
    methods: {
      forward () {
        this.allocationData = this.formData
      },
      getSubZoneList: function (millInfoId) {
        const subZoneList = this.$store.state.incentiveGrant.commonObj.subZoneList.filter(item => item && item.mill_info_id === millInfoId)
        return subZoneList.map(item => {
            if (this.$i18n.locale === 'bn') {
                return { value: item.value, text: item.text_bn }
            } else {
                return { value: item.value, text: item.text_en }
            }
        })
      },
      async loadData () {
      // this.cultivationMethod = this.customCultivationInfo()
      this.loading = true
      this.showData = true
      const params = Object.assign({}, this.search)
        // await RestApi.getData(incentiveGrantServiceBaseUrl, subzoneConsolidatedReport, params).then(response => {
        await RestApi.getData(incentiveGrantServiceBaseUrl, subsidyRequisitionAndApprovalMillWiseSubAmount, params)
        .then(response => {
          if (response.success) {
              if (this.search.fiscal_year_id) {
                const fiscalYearObj = this.$store.state.commonObj.fiscalYearList.find(fiscalYear => fiscalYear.value === this.search.fiscal_year_id)
                this.searchResult.fiscal_year = fiscalYearObj !== undefined ? fiscalYearObj.text_en : ''
                this.searchResult.fiscal_year_bn = fiscalYearObj !== undefined ? fiscalYearObj.text_bn : ''
              }
              // if (this.search.mill_info_id) {
              //   const millNameObj = this.$store.state.incentiveGrant.commonObj.millInfoList.find(millItem => millItem.value === this.search.mill_info_id)
              //   this.searchResult.mill_name = millNameObj !== undefined ? millNameObj.text_en : ''
              //   this.searchResult.mill_name_bn = millNameObj !== undefined ? millNameObj.text_bn : ''
              // }
              // if (this.search.subzone_office_id) {
              //   const subzoneNameObj = this.$store.state.incentiveGrant.commonObj.subZoneList.find(subzoneItem => subzoneItem.value === this.search.subzone_office_id)
              //   this.searchResult.subzone_office_name = subzoneNameObj !== undefined ? subzoneNameObj.text_en : ''
              //   this.searchResult.subzone_office_name_bn = subzoneNameObj !== undefined ? subzoneNameObj.text_bn : ''
              // }
              // this.datas = this.getRelatinalData(response.data)
              this.approvedList = response.approvedList
              this.approvedMillList = response.approvedMillList
              this.culMethod = response.culMethod
              this.policyLandGrandTotal = response.policyLandGrandTotal
              this.verifiedLandGrandTotal = response.verifiedLandGrandTotal
              this.amountGrandTotal = response.amountGrandTotal

              // this.formData.actual_subsidy_amount = response.amountGrandTotal
              // this.formData.allocated_subsidy_amount = response.amountGrandTotal
              // this.formData.subsidy_amount_percent = 100
              this.formData.allocated_amount = response.amountGrandTotal
              this.formData.actual_amount = response.amountGrandTotal
              this.formData.percentage = 100
              this.subsAllocate = response.subsAllocate
              this.loading = false
          } else {
            this.approvedList = []
            this.approvedMillList = []
            this.culMethod = []
          }
        })
        this.loading = false
      },
    //   getRelatinalData (data) {
    //     const centerList = this.$store.state.incentiveGrant.commonObj.centerList
    //     const unitList = this.$store.state.incentiveGrant.commonObj.unitList
    //     const listData = data.map(item => {
    //         const centerObject = centerList.find(center => center.value === item.center_id)
    //         const unitObject = unitList.find(unit => unit.value === item.unit_id)
    //         const ojbData = {
    //             center_name: centerObject !== undefined ? centerObject.text_en : '',
    //             center_name_bn: centerObject !== undefined ? centerObject.text_bn : '',
    //             unit_no: unitObject !== undefined ? unitObject.text_en : '',
    //             unit_no_bn: unitObject !== undefined ? unitObject.text_en : ''
    //         }
    //         return Object.assign({}, item, ojbData)
    //     })
    //     return listData
    //  },
      customCultivationInfo () {
        const cultivationPolicyList = this.$store.state.incentiveGrant.commonObj.cultivationPolicy.filter(item => item.fiscal_year_id === this.search.fiscal_year_id)
        const listData = cultivationPolicyList.map(item => {
          const cultivationMethodObject = this.$store.state.incentiveGrant.commonObj.cultivationMethodList.find(methodItem => methodItem.value === item.method_cultivation_id)

          const cultivationMethodData = {
            cultivationMethod_name: cultivationMethodObject !== undefined ? cultivationMethodObject.text_en : ' ',
            cultivationMethod_name_bn: cultivationMethodObject !== undefined ? cultivationMethodObject.text_bn : ' '
          }
          return Object.assign({}, item, cultivationMethodData)
          })

        return listData
      },
      async saveData () {
          this.loading = true
          const finalData = Object.assign(this.formData, { status: 3 })
          this.$store.dispatch('mutateCommonProperties', { loading: true })
          let result = null
          const loadingState = { loading: false, listReload: false }
          result = await RestApi.postData(incentiveGrantServiceBaseUrl, subsizeAlloApprove, finalData)

          loadingState.listReload = true
          this.$store.dispatch('mutateCommonProperties', loadingState)
          this.loading = false

          if (result.success) {
              this.$store.commit('incentiveGrant/mutateIncentiveGrantServiceProperties', { hasDropdownLoaded: false })
              this.$toast.success({
                title: this.$t('globalTrans.success'),
                message: this.id ? this.$t('globalTrans.update_msg') : this.$t('globalTrans.save_msg'),
                color: '#D6E09B'
              })

              this.$bvModal.hide('modal-4')
          } else {
              this.$refs.form.setErrors(result.errors)
          }
      }
    }
}
</script>
