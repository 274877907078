
<template>
    <b-container fluid>
        <iq-card>
            <template v-slot:headerTitle>
                <h4 class="card-title">{{ $t('bsri_demandDIS.subsidized_allocation') }}</h4>
            </template>
            <template v-slot:body>
                <b-row>
                  <b-col xs="12" sm="12" md="5">
                    <b-form-group
                    class="row"
                    label-cols-sm="4"
                    :label="$t('bsri_demandDIS.fiscal_year')"
                    label-for="fiscal_year_id"
                    >
                    <b-form-select
                    plain
                    v-model="search.fiscal_year_id"
                    :options="fiscalYearList"
                    id="fiscal_year_id"
                    >
                    <template v-slot:first>
                        <b-form-select-option :value="0">{{ $t('globalTrans.select') }}</b-form-select-option>
                    </template>
                    </b-form-select>
                    </b-form-group>
                  </b-col>
                  <b-col xs="12" sm="12" md="4">
                    <b-form-group
                    class="row"
                    label-cols-sm="2"
                    :label="$t('bsri_demandDIS.status')"
                    label-for="status"
                    >
                    <b-form-select
                    plain
                    v-model="search.status"
                    :options="statusList"
                    id="status"
                    >
                    <template v-slot:first>
                        <b-form-select-option :value="0">{{ $t('globalTrans.select') }}</b-form-select-option>
                    </template>
                    </b-form-select>
                    </b-form-group>
                  </b-col>
                  <b-col xs="12" sm="12" md="3">
                    <b-button type="button" variant="primary" @click="searchData">{{ $t('globalTrans.search')}}</b-button>
                  </b-col>
                </b-row>
            </template>
        </iq-card>
        <b-row>
            <b-col md="12">
                <iq-card>
                    <template v-slot:headerTitle>
                        <h4 class="card-title">{{ $t('bsri_demandDIS.subsidized_allocation_list') }}</h4>
                    </template>
                    <template v-slot:body>
                        <b-overlay :show="loadingState">
                            <b-row>
                                <b-col md="12" class="table-responsive">
                                    <b-table thead-class="table_head" :emptyText="$t('globalTrans.noDataFound')" show-empty bordered hover :items="listData" :fields="columns" aria-hidden="loading | listReload ? 'true' : null">
                                        <template v-slot:cell(index)="data">
                                            {{ $n(data.index + pagination.slOffset) }}
                                        </template>
                                        <template v-slot:cell(actual_amount)="data">
                                            {{ $n(data.item.actual_amount, { useGrouping: false }) }}
                                        </template>
                                        <template v-slot:cell(allocated_amount)="data">
                                            {{ $n(data.item.allocated_amount, { useGrouping: false }) }}
                                        </template>
                                         <template v-slot:cell(status)="data">
                                            {{ getStatus(data.item.status) }}
                                         </template>
                                         <template v-slot:cell(verfied)="data">
                                            <span class="badge badge-success" v-if="data.item.verfied == 1">{{$t('bsri_demandDIS.done')}}</span>
                                            <span class="badge badge-success" v-else>{{$t('bsri_demandDIS.not_done')}}</span>
                                        </template>
                                        <template v-slot:cell(action)="data">
                                            <slot v-if="data.item.status === 1">
                                                <a href="javascript:" class="btn_table_action table_action_status" title="Forward" v-b-modal.modal-1 @click="forward(data.item)"><i class="fas fa-arrow-right"></i></a>
                                                <a href="javascript:" class="btn_table_action table_action_edit" title="Edit" v-b-modal.modal-4 @click="edit(data.item)"><i class="ri-ball-pen-fill m-0"></i></a>
                                            </slot>
                                            <a href="javascript:" class="btn_table_action table_action_view" title="View" v-b-modal.modal-details @click="details(data.item)"><i class="fas fa-eye"></i></a>
                                        </template>
                                    </b-table>
                                    <b-pagination
                                      align="center"
                                      v-model="pagination.currentPage"
                                      :per-page="pagination.perPage"
                                      :total-rows="pagination.totalRows"
                                      @input="searchData"
                                    />
                                </b-col>
                            </b-row>
                        </b-overlay>
                    </template>
                </iq-card>
            </b-col>
        </b-row>
        <b-modal id="modal-details" size="xl" :title="$t('bsri_demandDIS.subsidy_requisition_and_approval')" :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
          <Details :fiscalYearId="fiscalYearId" :key="editItemId"/>
        </b-modal>
        <b-modal id="modal-4" size="xl" :title="formTitle" :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
          <Form :fiscalYearId="fiscalYearId" :key="editItemId"/>
        </b-modal>
    </b-container>
</template>
<script>
import Details from './Details'
import RestApi, { incentiveGrantServiceBaseUrl } from '@/config/api_config'
import { subsizeAlloList } from '../../api/routes'
import ModalBaseMasterList from '@/mixins/modal-base-master-list'

export default {
  mixins: [ModalBaseMasterList],
  components: {
    Details
  },
  data () {
    return {
      search: {
        fiscal_year_id: 0,
        status: 0
      },
      item: '',
      editItemId: '',
      fiscalYearId: '',
      loadingState: false
    }
  },
  mounted () {
  },
  computed: {
    fiscalYearList: function () {
        const fiscalYearList = this.$store.state.commonObj.fiscalYearList.filter(item => item.status === 0)
        return fiscalYearList.map(item => {
            if (this.$i18n.locale === 'bn') {
                return { value: item.value, text: item.text_bn }
            } else {
                return { value: item.value, text: item.text_en }
            }
        })
    },
    columns () {
      const labels = [
          { label: this.$t('globalTrans.sl_no'), class: 'text-left' },
          { label: this.$t('bsri_demandDIS.fiscal_year'), class: 'text-left' },
          { label: this.$t('bsri_demandDIS.requisition_amount'), class: 'text-left' },
          { label: this.$t('bsri_demandDIS.allocated_subsidy_amount'), class: 'text-left' },
          { label: this.$t('bsri_demandDIS.status'), class: 'text-left' },
          { label: this.$t('globalTrans.action'), class: 'text-center' }
        ]

      let keys = []

      if (this.$i18n.locale === 'bn') {
        keys = [
          { key: 'index' },
          { key: 'fiscal_year_bn' },
          { key: 'actual_amount' },
          { key: 'allocated_amount' },
          { key: 'status' },
          { key: 'action' }
        ]
      } else {
        keys = [
          { key: 'index' },
          { key: 'fiscal_year_en' },
          { key: 'actual_amount' },
          { key: 'allocated_amount' },
          { key: 'status' },
          { key: 'action' }
        ]
      }
      return labels.map((item, index) => {
        return Object.assign(item, keys[index])
      })
    },
    statusList: function () {
            const list = [
            { value: 2, text: this.$i18n.locale === 'en' ? 'Forward' : 'বিচারাধীন' },
            { value: 3, text: this.$i18n.locale === 'en' ? 'Approve' : 'অনুমোদন' }
            ]
           return list
        }
  },
  created () {
   this.loadData()
  },
  methods: {
    searchData () {
      this.loadData()
    },
    details (item) {
      this.item = item
      this.fiscalYearId = item.fiscal_year_id
    },
    async loadData () {
      this.loadingState = true
      const params = Object.assign({}, this.search, { page: this.pagination.currentPage, per_page: this.pagination.perPage })
      this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
      await RestApi.getData(incentiveGrantServiceBaseUrl, subsizeAlloList, params).then(response => {
        if (response.success) {
          this.$store.dispatch('setList', this.getRelatinalData(response.data.data))
          this.paginationData(response.data)
        } else {
          this.$store.dispatch('setList', [])
        }
        this.loadingState = false
        this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
      })
    },
    getRelatinalData (data) {
      const fiscalYearList = this.$store.state.commonObj.fiscalYearList.filter(item => item.status === 0)
      const listData = data.map(item => {
        const fiscalYearObj = fiscalYearList.find(fiscalYear => fiscalYear.value === item.fiscal_year_id)
        const fiscalYearData = { fiscal_year_en: fiscalYearObj?.text_en, fiscal_year_bn: fiscalYearObj?.text_bn }
        return Object.assign({}, item, fiscalYearData)
      })
      return listData
    },
    getStatus (status) {
      if (status === 1) {
        return this.$t('bsri_demandDIS.draft')
      } else if (status === 2) {
        return this.$t('bsri_demandDIS.forward')
      } else if (status === 3) {
        return this.$t('bsri_demandDIS.approved')
      } else if (status === 4) {
        return this.$t('bsri_demandDIS.disburse')
      }
    }
  }
}
</script>
